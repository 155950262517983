import React, { useState } from "react"
import { tracking } from "utils/tracking"
import {
  Container,
  TitleBar,
  Title,
  ContentContainer,
  MinusIcon,
  PlusIcon,
  CaretDownIcon,
  CaretUpIcon,
  InnerContainer,
} from "./AccordionItem.styles"
import { useEffect } from "react"
import { slugify } from "utils/stringUtils"
import { StyleType } from "typings/modules"
import { useClientRect } from "hooks/useClientRect"

export type AccordionItemProps = {
  eventTrackingId: string
  title: string
  styleType?: StyleType
  openByDefault?: boolean
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  eventTrackingId,
  title,
  styleType = "primary",
  openByDefault = false,
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(openByDefault)
  const [containerRect, containerRef] = useClientRect()
  const OpenIcon = styleType === "subtle" ? CaretUpIcon : MinusIcon
  const ClosedIcon = styleType === "subtle" ? CaretDownIcon : PlusIcon

  const toggleOpen = () => {
    setIsOpen(!isOpen)

    tracking.elementClicked(eventTrackingId, "Accordion Item")
  }
  const slugifiedTitle = slugify(title)

  useEffect(() => {
    if (isOpen) {
      tracking.elementViewed(eventTrackingId)
    }
  }, [eventTrackingId, isOpen])

  useEffect(() => {
    const hash = window.location.hash

    if (hash === `#${slugifiedTitle}` && containerRect?.top) {
      if (!isOpen) {
        toggleOpen()
      }
      const headerOffset = window.innerHeight / 3
      const elementPosition = containerRect.top
      const offsetPosition = elementPosition - headerOffset

      /**
       * This would be easier with "element.scrollIntoView",
       * but it has limited support on Safari
       */
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      })
    }
  }, [containerRect])

  return (
    <Container ref={containerRef} $styleType={styleType} {...props}>
      <TitleBar
        $styleType={styleType}
        aria-label={isOpen ? `Close Item ${title}` : `Open Item ${title}`}
        aria-pressed={isOpen}
        onClick={toggleOpen}
      >
        <Title $styleType={styleType}>{title}</Title>

        {isOpen ? (
          <OpenIcon role="img" aria-label="Close Item Icon" />
        ) : (
          <ClosedIcon role="img" aria-label="Open Item Icon" />
        )}
      </TitleBar>

      <ContentContainer $isOpen={isOpen}>
        <InnerContainer $styleType={styleType}>{children}</InnerContainer>
      </ContentContainer>
    </Container>
  )
}
