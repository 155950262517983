import React from "react"
import { SanityAccordionList } from "typings/graphql"
import { AccordionItem } from "components/AccordionItem/AccordionItem"
import {
  PrimaryGridContainer,
  SecondaryFullWidthContainer,
  TitleContent,
} from "./AccordionList.styles"
import { StyleType } from "typings/modules"
import { PortableText } from "components/UI/PortableText/PortableText"

export type CmsAccordionListProps = {
  index: number
} & SanityAccordionList

export const CmsAccordionList = ({
  items,
  _rawContent,
  bgColor,
}: CmsAccordionListProps) => (
  <AccordionList titleContent={_rawContent} bgColorHex={bgColor?.opacityHex}>
    {items?.map(item => (
      <AccordionItem
        key={`accordion-item-${item.id}`}
        eventTrackingId={item.internalName}
        openByDefault={item.expanded}
        title={item.title}
      >
        <PortableText blocks={item._rawContent} />
      </AccordionItem>
    ))}
  </AccordionList>
)

export type AccordionListProps = {
  bgColorHex?: string
  titleContent?: any
  styleType?: StyleType
  className?: string
} & React.ComponentProps<"div">

export const AccordionList: React.FC<AccordionListProps> = ({
  titleContent,
  bgColorHex,
  styleType = "primary",
  children,
  ...props
}) => {
  const Container: any =
    styleType === "secondary"
      ? SecondaryFullWidthContainer
      : PrimaryGridContainer

  return (
    <Container
      base="1 [10] 1"
      md="2 [12] 2"
      lg="6 [12] 6"
      $bgColor={bgColorHex}
      {...props}
    >
      {titleContent && (
        <TitleContent>
          <PortableText blocks={titleContent} />
        </TitleContent>
      )}
      {children}
    </Container>
  )
}
