import styled from "styled-components"
import { Grid } from "components/UI/Grid/Grid"
import { mq } from "styles"
import { $BgColor } from "typings/modules"

export const PrimaryGridContainer = styled(Grid)<$BgColor>`
  ${({ $bgColor }) => $bgColor && `background-color: ${$bgColor}`};
  padding-top: 64px;
  padding-bottom: 64px;

  ${mq.minWidth("md")} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
`

export const SecondaryFullWidthContainer = styled.div<$BgColor>`
  ${({ $bgColor }) => $bgColor && `background-color: ${$bgColor}`};

  width: 100%;
`

export const TitleContent = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.dark.text.primary.opacityHex};
  margin-bottom: 64px;
`
