import React from "react"
import { SanityAccordionList } from "typings/graphql"
import { CmsAccordionList } from "./AccordionList"

export const SanityAccordionListBlock = ({
  index,
  fields,
}: {
  fields: SanityAccordionList
  index: number
}) => <CmsAccordionList index={index} {...fields} />

export default SanityAccordionListBlock
