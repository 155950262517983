import styled, { css } from "styled-components"

import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import { animations, mq, typography } from "styles"
import MinusSvg from "assets/svg/minus.svg"
import PlusSvg from "assets/svg/plus.svg"
import CaretDown from "assets/svg/caret-down.svg"
import { $WithStyleType } from "typings/modules"

export type IsOpen = { $isOpen: boolean }

export const Container = styled.div<$WithStyleType>`
  border-top: 1px solid ${({ theme }) => theme.dark?.ui?.divider?.opacityHex};
  ${({ $styleType }) => $styleType === "subtle" && "border-top: none;"}

  &:last-of-type {
    border-bottom: 1px solid
      ${({ theme }) => theme.dark?.ui?.divider?.opacityHex};
  }
`

const primaryTitleBarStyle = css<$WithStyleType>`
  padding: 24px 0;

  ${mq.minWidth("md")} {
    padding: 32px 0;
  }
`

const subtleTitleBarStyle = css<$WithStyleType>`
  padding-top: 0;
  padding-bottom: 12px;
`

export const TitleBar = styled(NoStyleButton)<$WithStyleType>`
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;

  ${({ $styleType }) => $styleType === "subtle" && subtleTitleBarStyle}
  ${({ $styleType }) => $styleType === "primary" && primaryTitleBarStyle}

  transition: opacity 300ms ${animations.defaultBezier};

  ${mq.hasHover} {
    &:hover {
      opacity: 50%;
    }
  }
`

const secondaryTitleStyle = css`
  ${typography.bodySmall};
  font-weight: bold;
`

const subtleTitleStyle = css`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.dark.text.secondary.opacityHex};
`

export const Title = styled.h5<$WithStyleType>`
  color: ${({ theme }) => theme.dark.text.primary.opacityHex};
  margin-right: 16px;
  flex: 1;

  ${({ $styleType }) => $styleType === "secondary" && secondaryTitleStyle}
  ${({ $styleType }) => $styleType === "subtle" && subtleTitleStyle}
`

export const PlusIcon = styled(PlusSvg)`
  color: ${({ theme }) => theme.dark?.ui?.icon?.opacityHex};
`

export const MinusIcon = styled(MinusSvg)`
  color: ${({ theme }) => theme.dark?.ui?.icon?.opacityHex};
`

export const CaretDownIcon = styled(CaretDown)`
  color: ${({ theme }) => theme.dark?.ui?.icon?.opacityHex};
`
export const CaretUpIcon = styled(CaretDownIcon)`
  transform: rotate(180deg);
`

export const ContentContainer = styled.div<IsOpen>`
  transition: max-height 0.5s cubic-bezier(0.88, 0.13, 0.88, 0.13);
  overflow: hidden;
  max-height: 5000px;

  ${({ $isOpen }) =>
    !$isOpen &&
    `
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0.11, 0.91, 0.11, 0.91);
  `}
`

const primaryContainerStyle = css`
  margin-bottom: 24px;

  ${mq.minWidth("md")} {
    margin-bottom: 32px;
  }
`

const subtleContainerStyle = css`
  margin-bottom: 0;
`
export const InnerContainer = styled.div<$WithStyleType>`
  margin-bottom: 16px;

  ${({ $styleType }) => $styleType === "primary" && primaryContainerStyle}
  ${({ $styleType }) => $styleType === "subtle" && subtleContainerStyle}
`
